import * as React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import StickyHeader from '../../components/stickyHeader';
import { API_ENDPOINT } from '../../Platforms';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Autocomplete, IconButton, Paper, Snackbar, Stack, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { stockStatusEnums } from '../../Structures/Enums';
import { brandEnums } from '../../Database/Brands/Brands';
import { MAX_CATEGORY_LEVELS } from '../../Database/Categories/Categories';

import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import XHR from '@uppy/xhr-upload';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

import DefaultFormMap from '../../components/DefaultFormMap';
import { getFormValues } from '../../util/forms';
import { getFilterById, getFilterId, getVehicleEngine, getVehicleMakes, getVehicleModels, getVehicleSeries, getVehicleVariant } from '../../Database/Vehicles/vehicleList';

import ProgressModal from '../../components/ProgressModal';
import RemoveIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import { getFromLevel } from '../../components/CategoryMenu';

/* SAMPLE PRODUCT JSON FROM DB 
{
  "_id": 1,
  "_belongsTo": 0,
  "name": "Pro Series",
  "brand": "GJ Drivelines",
  "price": 500,
  "source": [],
  "image": [
    "/product_images/gj/pro.jpg",
    "/product_images/gj/tailshaft_2.jpg"
  ],
  "description": "For street cars which are still daily driven. Ultimate durability.",
  "productDescription": "Product Description text",
  "stockStatus": 1,
  "shippingCost": 50,
  "configurableOptions": [
    {
      "id": 1,
      "name": "Universal Joint Bolt ons",
      "image": "/product_images/gj/unis.jpg",
      "stockStatus": 1,
      "options": [
        {
          "id": 0,
          "name": "Standard - R22",
          "price": 0,
          "stockStatus": 1
        },
        {
          "id": 1,
          "name": "1320 Downgrade",
          "price": -300,
          "stockStatus": 0
        },
        {
          "id": 2,
          "name": "1340 Upgrade",
          "price": 300,
          "stockStatus": 1
        },
        {
          "id": 3,
          "name": "1350 Upgrade",
          "price": 500,
          "stockStatus": 1
        }
      ]
    },
    */



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const keepOnly = (items, state) => {
  Object.keys(state).forEach(prop => {
    if (!items.includes(prop)) {
      delete state[prop]
    }
  })
}

function ProductManagement(props) {

  const form = useRef(null);

  const [productDesc, setProductDesc] = useState("");


  // Categories - just an array of 'tags'
  const [productCategories, setProductCategories] = useState([]); // Main list to send to database

  // State for the source entries
  const [productSrc, setProductSrc] = useState([]);               // Main list to send to database

  // For vehicle filters
  const [selectedValues, setSelectedValues] = useState({});

  // State for the Product's Configurable Options
  const [productConfigOptions, setProductConfigOptions] = useState([]);


  // API Spinner control
  const [isWaitingAPI, setWaitingAPI] = useState(false);

  // Authorisation Status
  const [authorization, setAuthorization] = useState({ isAuthorized: false, username: "", password: "" });

  // SnackBar API invocation
  const [snackBarState, setSnackBarState] = useState({ alertSeverity: "", message: "", open: false });
  const snackBarAlertSeverityEnums = { error: "error", warning: "warning", info: "info", success: "success" };

  function displaySnackBar(alertSeverity, message) {
    setSnackBarState({ alertSeverity: alertSeverity, message: message, open: true })
  }

  const snackBarClose = (event, reason) => {
    if (reason === 'clickaway') { return; }
    setSnackBarState({ alertSeverity: snackBarState.alertSeverity, message: snackBarState.message, open: false });
  };

  // Login Handler
  function logIn() {
    const authInfo = {};

    if (!form.current) return;

    Object.keys(form.current.elements).forEach(element => {
      const thisElement = form.current.elements[element]
      if (thisElement.tagName === "INPUT") {
        if (!thisElement.value) return
        authInfo[thisElement.name] = thisElement.value
      }
    })

    // Validate input first
    if (authInfo.username == undefined) { displaySnackBar(snackBarAlertSeverityEnums.error, "Username Invalid"); return; }
    if (authInfo.password == undefined) { displaySnackBar(snackBarAlertSeverityEnums.error, "Password Invalid"); return; }

    // Send the belongs to if it is appended in the username
    // Syntax: username:belongsTo:id
    const splitArgs = authInfo.username.split(':');
    if (splitArgs.length == 3 && splitArgs[1] === 'belongsTo') {
      authInfo.username = splitArgs[0];
      authInfo._belongsTo = parseInt(splitArgs[2]);
    }

    // Attempt to log in
    setWaitingAPI(true);
    axios.post("https://" + API_ENDPOINT + "/adminauth", JSON.stringify(authInfo), {
      headers: {
        "content-type": "application/json"
      }
    }).then(res => {
      const auth = res.data.authorization;
      setAuthorization(auth);

      // Check if isAuthorized has been specified by the API.
      if (auth.isAuthorized)
        displaySnackBar(snackBarAlertSeverityEnums.success, "Login Success! - Welcome: " + auth.firstName + " " + auth.lastName);
      else displaySnackBar(snackBarAlertSeverityEnums.error, "Login Failed. Invalid Username / Password.");

      setWaitingAPI(false);
    })
  }

  // Submits the product to the
  async function submitProduct() {
    productConfigOptions.forEach(product => {
      product.uppy.upload()
        .then(res => {
          res.successful.forEach(item => {
            item.response.body.files.forEach(file => {
              product.image.push(file)
              console.log(product.image)
            })
          })
        })
    })
    // const uploadOperation = await uppy.upload()

    // Build the product here...
    const newProduct = {};

    newProduct.name = "K&N Oil Filter Spin On";
    newProduct.partNumber = "KNHP-1001";
    newProduct.brand = "K&N";
    newProduct.price = 25.00;
    newProduct.source = "https://www.repco.com.au/parts-service/filtration/oil-filters/k-n-oil-filter-spin-on-knhp-1001/p/A9403059";
    newProduct.image = ["https://www.repco.com.au/medias/sys_master/images/sys-master/images/h5c/h2f/10147446751262/A9403059/A9403059.jpg"];
    newProduct.description = "Heavy duty canister design";
    newProduct.productDescription = productDesc
    newProduct.quantity = 20;
    newProduct.shippingCost = 10.00;
    newProduct.configurableOptions = [];    // No configurable options
    newProduct.filters = {};                // No filters

    setWaitingAPI(true);
    axios.post("https://" + API_ENDPOINT + "/adminaddproduct", JSON.stringify({ ...authorization, ...newProduct }), {
      headers: {
        "content-type": "application/json"
      }
    }).then(res => {
      const response = res.data;

      // Display outcome
      if (response.status === 'success')
        displaySnackBar(snackBarAlertSeverityEnums.success, "Success!");
      else displaySnackBar(snackBarAlertSeverityEnums.error, "Error!");

      setWaitingAPI(false);
    })
  }

  function saveProduct(formValues) {
    const trackingNum = 12;
  }


  function addProduct() {

    const items = [

      { name: "name", label: "Name", defaultValue: "", disabled: false },
      { name: "partNumber", label: "Part Number", defaultValue: "", disabled: false },
      {
        name: "brand", label: "Brand", defaultValue: "", options:
          brandEnums.map((option) => (
            <MenuItem key={option.key} value={option.value}>
              {option.value}
            </MenuItem>
          ))
        , disabled: false
      },
      { name: "quantity", label: "Quantity", defaultValue: "", disabled: false },
      { name: "price", label: "Price", defaultValue: "", disabled: false },
      { name: "shippingCost", label: "Shipping Cost", defaultValue: "", disabled: false }
    ]

    const selectors = [
      {
        name: "make",
        funct: () => getVehicleMakes(),
        getRequires: () => true,
        cleanup: (state) => keepOnly(["make"], state)
      },
      {
        name: "model",
        funct: (state) => getVehicleModels(state.make),
        getRequires: (state) => state.make,
        cleanup: (state) => keepOnly(["make", "model"], state)
      },
      {
        name: "series",
        funct: (state) => getVehicleSeries(state.make, state.model),
        getRequires: (state) => state.make && state.model,
        cleanup: (state) => keepOnly(["make", "model", "series"], state)
      },
      {
        name: "engine",
        funct: (state) => getVehicleEngine(state.make, state.model, state.series),
        getRequires: (state) => state.make && state.model && state.series,
        cleanup: (state) => keepOnly(["make", "model", "series", "engine"], state)
      },
      {
        name: "variant",
        funct: (state) => getVehicleVariant(state.make, state.model, state.series, state.engine),
        getRequires: (state) => state.make && state.model && state.series && state.engine,
        cleanup: (state) => keepOnly(["make", "model", "series", "engine", "variant"], state)
      }
    ]


    return (
      <Grid container>
        <Grid xs={12} mb={1} ml={1} mr={1}>
          {/* Default Entries */}
          <DefaultFormMap form={form} items={items} />

          {/* Custom Stuff here */}

          {/* Product summary shown on product lists */}

          <TextField
            fullWidth
            onChange={(e) => {
              e.stopPropagation();
              // setFormValuesHook(order._id, "myobOrderNum", e.target.value)
            }}
            name={"productSummary"}
            label={"Summary"}
            disabled={authorization.type != 'administrator'}
            variant="outlined"
            autoComplete="off"
          />

          <Stack mt={1} />
          <TextField
            fullWidth
            name={"productDescription"}
            label={"Main Description"}
            multiline rows={10}
            disabled={authorization.type != 'administrator'}
            variant="outlined"
            autoComplete="off"
            value={productDesc} onChange={e => setProductDesc(e.target.value)}
          />
        </Grid>


        {/* Product Categories: store as array of strings in the db*/}
        <StickyHeader label='Product Categories' >
          <IconButton
            onClick={async () => { if (productCategories.length < MAX_CATEGORY_LEVELS) { productCategories.push(""); setProductCategories([...productCategories]); } }}
            size="medium"
            aria-label="close"
            color="inherit"
          >
            <AddIcon color='primary' fontSize="medium" />
          </IconButton>
        </StickyHeader>

        {productCategories.length > 0 && <Grid xs={12} sm={12}>
          <Paper variant='outlined' sx={{ margin: 1, padding: 1, flex: 1 }}>
            {productCategories.map((category, i) => {
              return <Grid xs={12}>
                <div style={{ display: "flex" }}>

                  <TextField fullWidth id="outlined-basic" name="category" label={"Category Level " + (i + 1)} variant="outlined" autoComplete="off" select
                    value={category} onChange={e => {
                      productCategories[i] = e.target.value
                      setProductCategories(productCategories.splice(0, i + 1))
                    }} >

                    options={getFromLevel(productCategories, i).map((option, x) => (
                      <MenuItem key={x} value={option}>
                        {option}
                      </MenuItem>
                    ))}

                  </TextField>

                  <IconButton
                    onClick={async () => {
                      setProductCategories(productCategories.splice(0, i))
                    }}
                    size="large"
                    aria-label="close"
                    color="inherit">
                    <RemoveIcon color='primary' fontSize="large" />
                  </IconButton>
                </div>
              </Grid>
            })}


          </Paper>
        </Grid>}


        {/* Product Source: list of links where the product can be sourced from - store as array of strings in the db*/}
        <StickyHeader label='Product Source Links'>
          <IconButton
            onClick={async () => { productSrc.push(""); setProductSrc([...productSrc]); }}
            size="medium"
            aria-label="close"
            color="inherit"
          >
            <AddIcon color='primary' fontSize="medium" />
          </IconButton>
        </StickyHeader>
        {productSrc.length > 0 && <Grid xs={12} sm={12}>
          <Paper variant='outlined' sx={{ margin: 1, padding: 1, flex: 1 }}>
            {productSrc.map((src, i) =>
              <Grid xs={12}>
                <div style={{ display: "flex" }}>

                  <TextField fullWidth id="outlined-basic" name="srcSet" label={"Source " + (i + 1)} variant="outlined" autoComplete="off"
                    value={src} onChange={e => {
                      productSrc[i] = e.target.value
                      setProductSrc([...productSrc])
                    }} />
                  <IconButton
                    onClick={async () => {
                      productSrc.splice(i, 1)
                      setProductSrc([...productSrc]);
                    }}
                    size="large"
                    aria-label="close"
                    color="inherit"
                  >
                    <RemoveIcon color='primary' fontSize="large" />
                  </IconButton>
                </div>
              </Grid>
            )}
          </Paper>
        </Grid>}

        {/* Product Vehicle Filters: Use vehicle filters from store page*/}
        {/* Product can contain multiple vehicle filters */}
        <StickyHeader label='Vehicle Filters' />

        <Grid xs={12} sm={12}>
          <Paper variant='outlined' sx={{ margin: 1, padding: 1, flex: 1 }}>
            <Grid container >
              {selectors.map(selector => (
                <Grid xs={12} sm={6} md={4} lg={3} sx={{ display: "flex" }}>
                  <Autocomplete
                    variant="outlined"
                    options={selector.funct(selectedValues)}
                    renderInput={(params) => <TextField {...params}
                      label={selector.name.toUpperCase()} />}
                    onChange={(e) => {
                      selector.cleanup(selectedValues)
                      setSelectedValues({ ...selectedValues, ...{ [selector.name]: e.target.innerText } })
                    }}
                    value={selectedValues[selector.name] || ""}
                    sx={{ flexGrow: 1, minWidth: 150, flexGrow: 1, mt: 1, mb: 1, ml: 1, mr: 1 }}
                    item
                    disabled={!selector.getRequires(selectedValues)}
                  >
                  </Autocomplete>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>


        {/*------------------------ Product Configurable options: ------------------------*/}
        <StickyHeader label='Product Configurable Options'>
          <IconButton
            onClick={async () => {

              const uppy = new Uppy({
                onBeforeUpload: (files) => {
                  for (let file in files) {
                    if (file.includes("localhost")) {
                      // Get criteria for if file is not new
                      uppy.setFileState(file, {
                        progress: { uploadComplete: true, uploadStarted: true },
                        uploadURL: "https://example.com/path/to/myfile.pdf", // optional - for use with showLinkToFileUploadResult
                      });
                    }
                  }
                }
              })
                .use(XHR, { endpoint: 'http://localhost:5000/upload' })
                .on('file-removed', () => {

                })
              const newItem = {
                name: "", image: [
                  "1708791869084.jpg",
                  "1709036231661.jpg"
                ], stockStatus: "", subOptions: [], uppy
              }

              productConfigOptions.push(newItem);

              newItem.image.forEach(previous => {
                fetch(`http://localhost:5000/static/${previous}`)
                  .then((response) => response.blob())
                  .then((blob) => {
                    const exists = uppy.checkIfFileAlreadyExists(previous)
                    if (exists) return;

                    uppy.addFile({
                      name: previous,
                      type: blob.type,
                      data: blob
                    })

                  }).catch(err => {
                    console.log(err)
                  })
              })

              // only set this after initial files have been added
              /*uppy.setOptions({
                autoProceed: true,
              });*/
              setProductConfigOptions([...productConfigOptions]);
            }}
            size="medium"
            aria-label="close"
            color="inherit" >
            <AddIcon color='primary' fontSize="medium" />
          </IconButton>
        </StickyHeader>
        {productConfigOptions.map((option, i) =>
          <Grid xs={12} sm={4} padding={1}>
            <Paper variant="outlined">
              <Grid ml={1} mr={1} mb={1}>

                <Grid container>

                  <Grid sx={12} sm={8}>
                    {/* Option Name */}
                    <TextField fullWidth id="outlined-basic" name="optionName" label="Option Name" variant="standard" autoComplete="off"
                      value={option.name} onChange={e => {
                        productConfigOptions[i].name = e.target.value
                        setProductConfigOptions([...productConfigOptions])
                      }} />
                  </Grid>
                  <Grid sx={12} sm={4}>
                    {/* Option Stock Status - Use Enums as drop down */}
                    <TextField fullWidth id="outlined-basic" name="optionStockStatus" label="Option Stock Status" variant="standard" autoComplete="off" select
                      value={option.stockStatus}

                      onChange={e => {
                        productConfigOptions[i].stockStatus = e.target.value
                        setProductConfigOptions([...productConfigOptions])
                      }}>

                      options={stockStatusEnums.map((option) => (
                        <MenuItem key={option.key} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}

                    </TextField>
                  </Grid>
                </Grid>

                <Grid mt={1} sx={{ display: "flex", justifyContent: "flex-end" }}>

                  {/* Option delete button */}
                  <Button
                    onClick={async () => {
                      productConfigOptions.splice(i, 1)
                      setProductConfigOptions([...productConfigOptions]);
                    }}>
                    Delete
                  </Button>
                </Grid>
              </Grid>


              <Dashboard id={i.toString()}
                proudlyDisplayPoweredByUppy={false}
                uppy={option.uppy} hideUploadButton
                hideCancelButton width="100%" hideProgressAfterFinish
                singleFileFullScreen={false} height={420}
              />

              {/* Sub options second row */}
              <Grid sx={{ display: "flex", alignItems: "center" }}>

                <Typography variant="subtitle1" ml={2} mt={1} mb={1}>
                  Sub Options
                </Typography>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <IconButton
                    onClick={async () => { productConfigOptions[i].subOptions.push({ name: "", price: "", stockStatus: "" }); setProductConfigOptions([...productConfigOptions]); }}
                    size="small"
                    aria-label="close"
                    color="inherit" >
                    <AddIcon color='primary' fontSize="small" />
                  </IconButton>
                </div>
              </Grid>

              <Grid xs={12} sm={12}>
                {productConfigOptions[i].subOptions.map((option, j) =>
                  <Grid xs={12} mt={-1}>
                    <Grid sx={{ display: "flex", ml: 1, }}>

                      {/* Name */}
                      <TextField fullWidth id="outlined-basic" name="subOptionName" label="Name" variant="standard" autoComplete="off" sx={{ mb: 1 }}
                        value={option.name} onChange={e => {
                          productConfigOptions[i].subOptions[j].name = e.target.value
                          setProductConfigOptions([...productConfigOptions])
                        }} />

                      {/* Price */}
                      <TextField fullWidth id="outlined-basic" name="subOptionPrice" label="Price" variant="standard" autoComplete="off"
                        value={option.price} onChange={e => {
                          productConfigOptions[i].subOptions[j].price = e.target.value
                          setProductConfigOptions([...productConfigOptions])
                        }} />

                      {/* Stock Status - Use Enums as drop down */}
                      <TextField fullWidth id="outlined-basic" name="subOptionStockStatus" label="Stock Status" variant="standard" autoComplete="off" select
                        value={option.stockStatus} onChange={e => {
                          productConfigOptions[i].subOptions[j].stockStatus = e.target.value
                          setProductConfigOptions([...productConfigOptions])
                        }}>

                        options={stockStatusEnums.map((option) => (
                          <MenuItem key={option.key} value={option.value}>
                            {option.value}
                          </MenuItem>
                        ))}

                      </TextField>

                      {/* Sub option delete button */}
                      <IconButton
                        onClick={async () => {
                          productConfigOptions[i].subOptions.splice(j, 1)
                          setProductConfigOptions([...productConfigOptions]);
                        }}
                        size="medium"
                        aria-label="close"
                        color="inherit"
                        sx={{ mt: 1 }} >
                        <RemoveIcon color='primary' fontSize="medium" />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}

              </Grid>
            </Paper>
          </Grid>
        )}



        {/* {currentPics.map((item, i) => <div style={{ width: 100, height: 100, backgroundColor: "red", marginLeft: "1em", marginRight: "1em", position: "relative", opacity: item.delete ? 0.5 : 1 }}>
            <IconButton
              style={{ position: "absolute", right: 0, top: 0 }}
              size="small"
              aria-label="close"
              color="inherit"
            >
              <RemoveIcon fontSize="small" onClick={() => {
                item.delete = !item.delete;
                setCurrentPics([...currentPics])
              }} />
            </IconButton>
          </div>)} */}
        {/* Product Configurable Options - Multi-nest arrays of options ? */}
        <Grid xs={6} pr={1}>
          <Button fullWidth  variant="outlined" onClick={submitProduct}>Save to DB</Button>
        </Grid>
        <Grid xs={6}>
          {<Button fullWidth pl={1} variant="outlined" onClick={async () => {
            saveProduct({ formValues: getFormValues(form) })
          }}>Save Changes</Button>}
        </Grid>
      </Grid >
    )
  }

  // User Logged in - show all the product management configuration options
  if (authorization.isAuthorized) {
    return (<Box>

      <StickyHeader label={"Product Management: " + authorization.firstName + " " + authorization.lastName + " @ " + authorization.organization} />

      <ProgressModal display={isWaitingAPI} />

      {addProduct()}

      <Snackbar
        open={snackBarState.open}
        autoHideDuration={5000}
        onClose={snackBarClose}>
        <Alert onClose={snackBarClose} severity={snackBarState.alertSeverity} sx={{ width: '100%' }}>{snackBarState.message}</Alert>
      </Snackbar>

    </Box>)
  }

  // User Not logged in ... show the login form
  if (!authorization.isAuthorized) {
    return (<Box>
      <StickyHeader label="Product Management: Login" />

      <form style={{ display: "flex", flexDirection: "column" }} ref={form}>
        <TextField sx={{ mt: 1, mb: 3 }} id="outlined-basic" name="username" label="Username" variant="outlined" />
        <TextField sx={{ mb: 3 }} id="outlined-basic" name="password" label="Password" variant="outlined" />
      </form>
      <Button sx={{ mb: 8 }} variant="outlined" onClick={logIn}>Login</Button>

      <Snackbar
        open={snackBarState.open}
        autoHideDuration={5000}
        onClose={snackBarClose}>
        <Alert onClose={snackBarClose} severity={snackBarState.alertSeverity} sx={{ width: '100%' }}>{snackBarState.message}</Alert>
      </Snackbar>
    </Box>)
  }
};

export default ProductManagement;