import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import StickyHeader from '../components/stickyHeader';
import { fetchPlatformData, API_ENDPOINT } from '../Platforms';
import { Box, Stack, CircularProgress, IconButton, Snackbar, snackbarClasses } from '@mui/material';
import SocialIcons from '../components/SocialIcons';
import MuiAlert from '@mui/material/Alert';
import { useState } from 'react';
import axios from 'axios';

const branches = fetchPlatformData('contactUs');

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const locationCard = (branch) => (
  <Card variant='outlined'>
    <iframe src={branch.branch_map_link} width="100%" height="480" frameborder="0" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    <CardContent>
      <Typography sx={{ mt: 1 }} gutterBottom variant="h6" component="div">
        <b>{branch.branch_name}</b>
      </Typography>
      <Typography variant="body1" color="text.primary">
        <b>Phone Number:</b> {branch.phone_number}
      </Typography>
      <Typography variant="body1" color="text.primary" >
        <b>Email:</b> {branch.email}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1" color="text.primary">
        <b>Trading Hours</b>
      </Typography>
      <Typography variant="body1" color="text.primary">
        {branch['trading_hours:'].map((value, i) => <Typography key={i} variant="body1" color="text.primary" >{value}</Typography>)}
      </Typography>
      <Stack sx={{ display: "flex", flexDirection: "row", marginTop: 2 }}>
        {branch['social_links'].map((social, i) => {
          return (
            <React.Fragment>
              <a href={social.link} style={{ color: "inherit", textDecoration: "none" }}>
                <Tooltip title={social.name}>
                  {SocialIcons[social.name]()}
                </Tooltip>
              </a >
              <Box sx={{ marginRight: 2 }} />
            </React.Fragment>
          )
        })}
      </Stack>

    </CardContent>
  </Card>)

const ContactUs = () => {

  // State for the input fields
  const [name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  // Global validation control
  const [formValidation, setFormValidation] = useState(false);

  // API Spinner control
  const [isWaitingAPI, setWaitingAPI] = useState(false);

  // SnackBar API invocation
  const [snackBarState, setSnackBarState] = useState({ alertSeverity: "", message: "", open: false });
  const snackBarAlertSeverityEnums = { error: "error", warning: "warning", info: "info", success: "success" };

  function displaySnackBar(alertSeverity, message) {
    setSnackBarState({ alertSeverity: alertSeverity, message: message, open: true })
  }

  const snackBarClose = (event, reason) => {
    if (reason === 'clickaway') { return; }
    setSnackBarState({ alertSeverity: snackBarState.alertSeverity, message: snackBarState.message, open: false });
  };


  // Submit messages button
  function submitMessage() {

    setFormValidation(true);

    // Validate inputs
    if ((name.length > 0) && (emailAddress.length > 0) && (phoneNumber.length > 0) && (subject.length > 0) && (message.length > 0)) {

      const apiMsg = {};
      apiMsg.name = name;
      apiMsg.emailAddress = emailAddress;
      apiMsg.phoneNumber = phoneNumber;
      apiMsg.subject = subject;
      apiMsg.message = message;

      // Attempt to send message to the API
      setWaitingAPI(true);
      axios.post("https://" + API_ENDPOINT + "/contactus", JSON.stringify(apiMsg), {
        headers: {
          "content-type": "application/json"
        }
      }).then(res => {
        const status = res.data.status;

        if (status === 'success')
          displaySnackBar(snackBarAlertSeverityEnums.success, "Thanks for contacting us. We will reach out to you soon!");
        else displaySnackBar(snackBarAlertSeverityEnums.error, "Error sending message. Contact us instead!");

        // Reset the form fields now...
        setFormValidation(false);
        setName("");
        setEmailAddress("");
        setPhoneNumber("");
        setSubject("");
        setMessage("");

        // Hide spinner
        setWaitingAPI(false);
      })
    }
  }

  return (
    <React.Fragment>

      <StickyHeader label="Our Locations" />
      <Grid container spacing={0.5} marginLeft={0} marginRight={0}>
        {branches.map((branch, i) =>
          <Grid key={i} xs={12} sm={12 / branches.length} md={12 / branches.length} lg={12 / branches.length} sx={{ marginTop: 0 }}>
            {locationCard(branch)}
          </Grid>
        )}
      </Grid>

      {!fetchPlatformData('staticPage') ?

        <React.Fragment>
          <Box margin={2} />
          <StickyHeader label="Send us a message" />

          <Grid container spacing={1} ml={1} mr={1}>
            <Grid xs={12} sm={6}>
              <TextField fullWidth id="outlined-basic" name="name" label="Your Name" variant="outlined" value={name} onChange={e => setName(e.target.value)} error={!name && formValidation} helperText={!name && formValidation ? "can't be empty" : undefined} />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField fullWidth id="outlined-basic" name="emailAddress" label="Email Address" variant="outlined" type="email" value={emailAddress} onChange={e => setEmailAddress(e.target.value)} error={!emailAddress && formValidation} helperText={!emailAddress && formValidation ? "can't be empty" : undefined} />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField fullWidth id="outlined-basic" name="phoneNumber" label="Phone Number" variant="outlined" type="phoneNumber" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} error={!phoneNumber && formValidation} helperText={!phoneNumber && formValidation ? "can't be empty" : undefined} />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField fullWidth id="outlined-basic" name="subject" label="Subject" variant="outlined" value={subject} onChange={e => setSubject(e.target.value)} error={!subject && formValidation} helperText={!subject && formValidation ? "can't be empty" : undefined} />
            </Grid>
            <Grid xs={12}>
              <TextField fullWidth id="outlined-multiline-static" name="message" label="Enter your message here!" multiline rows={4} value={message} onChange={e => setMessage(e.target.value)} error={!message && formValidation} helperText={!message && formValidation ? "can't be empty" : undefined} />
            </Grid>
          </Grid>

          <CardActions sx={{ justifyContent: "center" }}>
            {isWaitingAPI ? <CircularProgress thickness={2} size={"5em"} /> : <Button variant="outlined" onClick={submitMessage}>Submit</Button>}
          </CardActions>

          <Snackbar
            open={snackBarState.open}
            autoHideDuration={5000}
            onClose={snackBarClose}>
            <Alert onClose={snackBarClose} severity={snackBarState.alertSeverity} sx={{ width: '100%' }}>{snackBarState.message}</Alert>
          </Snackbar>

        </React.Fragment> : <></>
      }
    </React.Fragment>
  )
};

export default ContactUs;